.lineClam {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	white-space: normal;

	-webkit-box-orient: vertical;
	line-clamp: 2;
}

.lineClam1 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	white-space: normal;

	-webkit-box-orient: vertical;
	line-clamp: 1;
}
