.wrapperInputForm {
	.input {
		height: 35px;
		border-radius: 8px;
		outline: none;
		border: 0.0625rem solid black;
	}

	.errors {
		color: red;
	}
}
