.wrapper {
	width: 250px;
	height: 100vh;
	transition: transform 0.3s ease-in-out;
	color: #006b35;
	position: fixed;
	z-index: 401;

	:global {
		.ps-sidebar-container {
			background: white;
		}

		.ps-submenu-expand-icon {
			display: flex;
		}

		.ps-menuitem-root {
			background: white;

			.ps-menu-button {
				margin-bottom: 10px;
				height: 40px;
				border-radius: 8px;

				&:hover {
					font-weight: 700;
				}
			}
		}
	}

	.container {
		width: 250px;
		height: 100vh;
		transition: transform 0.3s ease-in-out;
		background: white;
		box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;

		::-webkit-scrollbar {
			display: none;
		}

		.sidebar {
			width: 100%;
			height: 100vh;
			background: white;
			border: 0px;
			padding: 10px;
			box-sizing: border-box;

			.menu {
				// background: red;
				background: white;

				.SubMenu {
					// background: blue;
					background: white;
				}

				.active {
					color: white;

					a {
						background: #006b35;
						font-weight: 700;

						/* Loại bỏ dấu ngoặc đơn và chuỗi không hợp lệ */
					}
				}
			}
		}
	}
}

.closeSidebar {
	display: none;
	position: absolute;
	bottom: 10px;
	right: 10px;
	height: 20px;
	width: 20px;
	z-index: 3;
	cursor: pointer;
}

@media (max-width: 900px) {
	.wrapper {
		position: fixed;
		z-index: 401;

		.show {
			transform: translateX(0);
		}

		.hide {
			transform: translateX(-100%);
		}

		.closeSidebar {
			display: flex;
		}
	}

	.show {
		width: 250px;
	}

	.hide {
		width: 0;
		left: -1px;
	}
}
