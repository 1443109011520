@import '../../global.module.scss';

.containerLogin {
	max-height: 500px;
	max-width: 460px;
	box-shadow: $primary-boxShadow;
	padding: 72px 40px;
	border-radius: $primary-radius;
	background: white;
	width: calc(100% - 80px) !important;

	img {
		border-radius: $primary-radius;
	}
}

@media (max-width: 600px) {
}
