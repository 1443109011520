@import '../../../global.module.scss';

.title {
	font-size: 24px !important;
	text-align: center;
	font-weight: $bold-fontWeight !important;
}

.formChangePass {
	padding: 16px;
}
