.header {
	height: 50px;
	padding: 0 20px;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

	.toggleSideBar {
		display: none;
		cursor: pointer;
	}
}

.menuContainer {
	:global {
		.MuiPaper-root {
			width: 200px;
		}
	}
}

@media (max-width: 900px) {
	.header {
		.toggleSideBar {
			display: flex;
		}
	}
}
